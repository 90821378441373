// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var PrismJs = require("../utils/PrismJs.bs.js");
var Markdown = require("../utils/Markdown.bs.js");

((require("./MarkdownBlock.css")));

function randomId(param) {
  var randomComponent = Math.random().toString().substr(2);
  return "markdown-block-" + randomComponent;
}

function profileClasses(profile) {
  switch (profile) {
    case /* Comment */0 :
        return "markdown-block__comment ";
    case /* QuestionAndAnswer */1 :
        return "markdown-block__question-and-answer ";
    case /* Permissive */2 :
        return "markdown-block__permissive ";
    case /* AreaOfText */3 :
        return "markdown-block__area-of-text ";
    
  }
}

function markdownBlockClasses(profile, className) {
  var defaultClasses = "markdown-block " + profileClasses(profile);
  if (className !== undefined) {
    return defaultClasses + className;
  } else {
    return defaultClasses;
  }
}

function MarkdownBlock(Props) {
  var markdown = Props.markdown;
  var className = Props.className;
  var profile = Props.profile;
  var match = React.useState(function () {
        return randomId(undefined);
      });
  var id = match[0];
  React.useEffect((function () {
          PrismJs.highlightAllUnder(id);
          
        }), [markdown]);
  return React.createElement("div", {
              className: markdownBlockClasses(profile, className),
              id: id,
              dangerouslySetInnerHTML: {
                __html: Markdown.parse(profile, markdown)
              }
            });
}

var make = MarkdownBlock;

exports.randomId = randomId;
exports.profileClasses = profileClasses;
exports.markdownBlockClasses = markdownBlockClasses;
exports.make = make;
/*  Not a pure module */
