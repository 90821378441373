// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var PrismjsWrapper = require("./prismjsWrapper").default;

function highlightAllUnderJs(prim) {
  PrismjsWrapper(prim);
  
}

function highlightAllUnder(elementId) {
  var wrapperElement = document.getElementById(elementId);
  if (!(wrapperElement == null)) {
    PrismjsWrapper(wrapperElement);
    return ;
  }
  
}

exports.highlightAllUnderJs = highlightAllUnderJs;
exports.highlightAllUnder = highlightAllUnder;
/* ./prismjsWrapper Not a pure module */
